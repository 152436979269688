import React, { useRef, useEffect, useState } from "react";
import Globe from "react-globe.gl";
import * as THREE from "three";
import ReactDOMServer from "react-dom/server";
import { Checkbox } from "@nextui-org/checkbox";
import { Button } from "@nextui-org/button";
import { Card, CardBody } from "@nextui-org/card";
import { NextUIProvider } from "@nextui-org/react";
import { Dropdown, DropdownTrigger, DropdownMenu } from "@nextui-org/dropdown";
import { Image } from "@nextui-org/image";
import {
  Drawer,
  DrawerContent,
  DrawerHeader,
  DrawerBody,
  DrawerFooter,
  useDisclosure,
} from "@nextui-org/react";

const VenueGlobe = () => {
  const globeEl = useRef();

  const [rotating, setRotating] = useState(false);

  const bufferTimeoutRef = useRef(null); // To manage the buffer

  const { isOpen, onOpen, onOpenChange } = useDisclosure();

  console.log(globeEl?.current?.scene());
  const [places, setPlaces] = useState([]);
  const [pointsData, setPointsData] = useState([]);
  const [selectedIntegrations, setSelectedIntegrations] = useState([
    "opentable_count",
    "resy_count",
    "tock_count",
    "trip_count",
    "resdiary_count",
    "design_count",
    "quandoo_count",
    "fork_count",
    "sevenrooms_count",
  ]);

  // const [shouldRotate, setShouldRotate] = useState(true);

  useEffect(() => {
    globeEl.current.pointOfView({ lat: 51.5072, lng: 0.1276, altitude: 3 }, 0);

    setTimeout(() => {
      globeEl.current.controls().autoRotate = true;
      globeEl.current.controls().autoRotateSpeed = -0.3;
      setRotating(true)
    }, 2000);
  }, []);

  useEffect(() => {
    if (rotating) {
      setRotating(false);
      animateAutoRotateSpeed(
        globeEl.current.controls().autoRotateSpeed,
        0,
        500,
        () => {
          globeEl.current.controls().autoRotate = false;
        }
      );
    } else {
      globeEl.current.controls().autoRotate = true;
      animateAutoRotateSpeed(0, -0.3, 500, () => {
        setRotating(true);
      });
    }
  }, [rotating]);

  const animationRef = useRef(null); // To track the animation
  const isAnimatingRef = useRef(false); // To prevent overlapping animations

  // Function to animate `autoRotateSpeed`
  const animateAutoRotateSpeed = (
    startSpeed,
    endSpeed,
    duration,
    onComplete
  ) => {
    if (isAnimatingRef.current) return; // Prevent overlapping animations
    isAnimatingRef.current = true;

    const startTime = performance.now();

    const animate = (currentTime) => {
      const elapsedTime = currentTime - startTime;
      const progress = Math.min(elapsedTime / duration, 1); // Clamp progress to [0, 1]
      const newSpeed = startSpeed + progress * (endSpeed - startSpeed);

      globeEl.current.controls().autoRotateSpeed = newSpeed;

      if (progress < 1) {
        animationRef.current = requestAnimationFrame(animate);
      } else {
        isAnimatingRef.current = false;
        if (onComplete) onComplete();
      }
    };

    animationRef.current = requestAnimationFrame(animate);
  };

  useEffect(() => {
    return () => {
      if (bufferTimeoutRef.current) {
        clearTimeout(bufferTimeoutRef.current);
      }
      if (animationRef.current) {
        cancelAnimationFrame(animationRef.current);
      }
    };
  }, []);

  /* useEffect(() => {
    if (globeEl.current) {
      if (shouldRotate) {
        globeEl.current.resumeAnimation()
      } else {
      globeEl.current.pauseAnimation()
      }
    }
  }, [shouldRotate]); */

  const providerTypes = [
    { key: "opentable_count", color: "#F94144" },
    { key: "resy_count", color: "#F9844A" },
    { key: "tock_count", color: "#277DA1" },
    { key: "trip_count", color: "#F9C74F" },
    { key: "resdiary_count", color: "#4D908E" },
    { key: "design_count", color: "#F8961E" },
    { key: "quandoo_count", color: "#43AA8B" },
    { key: "fork_count", color: "#90BE6D" },
    { key: "sevenrooms_count", color: "#577590" },
  ];

  useEffect(() => {
    // load data
    fetch("/map_labels.geojson")
      .then((res) => res.json())
      .then(({ features }) => setPlaces(features));
  }, []);

  useEffect(() => {
    fetch("/results.csv") // Replace with your actual CSV file path
      .then((res) => res.text())
      .then((csv) => {
        const rows = csv.split("\n");
        const headers = rows[0].split(",");
        const data = rows.slice(1).map((row) => {
          const values = row.split(",");
          const point = {};
          headers.forEach((header, index) => {
            point[header] = isNaN(values[index])
              ? values[index]
              : parseFloat(values[index]);
          });
          return point;
        });

        // Transform data for Globe
        setPointsData(
          data.map((point) => ({
            lat: point.latitude,
            lng: point.longitude,
            counts: providerTypes.reduce((acc, { key }) => {
              acc[key] = point[key] || 0;
              return acc;
            }, {}),
          }))
        );
      });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const ToggleContainer = () => (
    <div className="flex flex-col items-left space-y-1 p-3">
      {providerTypes.map(({ key, color }) => (
        <label key={key} style={{ color }}>
          <Checkbox
            type="checkbox"
            isSelected={selectedIntegrations.includes(key)}
            onValueChange={() => toggleIntegration(key)}
          />
          {key.replace("_count", "").toUpperCase()}
        </label>
      ))}
    </div>
  );

  const HoverCard = ({ content }) => (
    <Card
      isBlurred
      className="border-none bg-background/60 dark:bg-default-100/50 max-w-[610px]"
      shadow="sm"
      m
    >
      <CardBody>
        <div className="flex flex-col items-left p-4">
          <h1 className="text-large font-medium">Statistics</h1>
          {content.map((c) => (
            <p className="text-small text-foreground/80">{c}</p>
          ))}
        </div>
      </CardBody>
    </Card>
  );

  const toggleIntegration = (key) => {
    setSelectedIntegrations(
      (prev) =>
        prev.includes(key)
          ? prev.filter((k) => k !== key) // Remove if already selected
          : [...prev, key] // Add if not selected
    );
  };

  return (
    <NextUIProvider className="dark">
      <>
        <Globe
          ref={globeEl}
          backgroundColor="black"
          atmosphereColor="white"
          labelsData={places}
          labelLat={(d) => d.properties.latitude}
          labelLng={(d) => d.properties.longitude}
          labelText={(d) => d.properties.name}
          labelSize={(d) => Math.sqrt(d.properties.pop_max) * 2e-4}
          labelDotRadius={0}
          labelColor={() => "rgba(255, 255, 255, 0.5)"}
          labelAltitude={0.1}
          labelResolution={2}
          globeImageUrl={"//unpkg.com/three-globe/example/img/earth-dark.jpg"}
          backgroundImageUrl="//unpkg.com/three-globe/example/img/night-sky.png"
          bumpImageUrl="//unpkg.com/three-globe/example/img/earth-topology.png"
          objectsData={pointsData}
          onObjectHover={(object) => {
            if (!object) {
              console.log("unhovered");

              // Clear any previous timeout if already hovering
              if (bufferTimeoutRef.current) {
                clearTimeout(bufferTimeoutRef.current);
                bufferTimeoutRef.current = null;
              }

              // Set a timeout to start rotating again after 1 second
              bufferTimeoutRef.current = setTimeout(() => {
                globeEl.current.controls().autoRotate = true;
                setRotating(true);
                bufferTimeoutRef.current = null; // Clear timeout reference
              }, 1000); // 1-second buffer
            } else {
              console.log("hovered");

              // Stop rotation and clear any running timeout
              globeEl.current.controls().autoRotate = false;
              setRotating(false);
              if (bufferTimeoutRef.current) {
                clearTimeout(bufferTimeoutRef.current);
                bufferTimeoutRef.current = null;
              }
            }
          }}
          objectLat="lat"
          objectLng="lng"
          objectLabel={(point) => {
            const { counts } = point;
            return ReactDOMServer.renderToString(
              <HoverCard
                content={Object.keys(counts)
                  .filter((key) => counts[key] > 0)
                  .sort((key) => counts[key])
                  .map((key) => `${key.replace("_count", "")}: ${counts[key]}`)}
              />
            );
          }}
          objectRotation={{ x: 0, y: 90, z: 90 }}
          objectThreeObject={(point) => {
            const group = new THREE.Group();
            const { counts } = point;

            let heightOffset = 0;
            providerTypes.forEach(({ key, color }) => {
              if (selectedIntegrations.includes(key) && counts[key] > 0) {
                const segmentHeight = counts[key] / 100; // Scale height

                // Use MeshStandardMaterial for better lighting effects
                const material = new THREE.MeshPhysicalMaterial({
                  color, // Base color
                  emissive: color, // Slight glow
                  emissiveIntensity: 0.5,
                  metalness: 0.3, // Adds a slight metallic look
                  roughness: 0.7, // Controls the roughness of the surface
                  opacity: 0.5,
                });

                const geometry = new THREE.CylinderGeometry(
                  0.5,
                  0.5,
                  segmentHeight,
                  5
                ); // Increase segments for smoother cylinders
                const cylinder = new THREE.Mesh(geometry, material);

                // Position cylinders vertically within the group
                cylinder.position.y = heightOffset + segmentHeight / 2;
                group.add(cylinder);

                // Increment height offset for stacking
                heightOffset += segmentHeight;
              }
            });

            return group;
          }}
          objectAltitude={0.001} // Set altitude of the base of objects
          objectFacesSurface={true} // Ensure objects face outward
        />

        <div
          style={{
            width: "200px",
            position: "absolute",
            left: window.innerWidth / 2 - 50,
            top: 10,
          }}
        ></div>

        {!isOpen && (
          <div
            style={{
              position: "absolute",
              bottom: 20,
              left: 20,
              color: "white",
              display: "flex",
              flexDirection: "column",
            }}
          >
            <Dropdown
              isBlurred
              className="border-none bg-background/60 dark:bg-default-100/50 max-w-[610px] dark"
            >
              <DropdownTrigger>
                <Button variant="bordered">Filter</Button>
              </DropdownTrigger>
              <DropdownMenu emptyContent={<ToggleContainer />}></DropdownMenu>
            </Dropdown>
          </div>
        )}

        {!isOpen && (
          <div
            className="dark"
            style={{
              position: "absolute",
              bottom: 20,
              right: 20,
            }}
          >
            <Button
              className="dark"
              onPress={() => onOpen()}
              startContent={
                <Image
                  className="w-6 h-6 rounded"
                  src="https://framerusercontent.com/images/6mLh071sty5bzTNZ6IRiKcCyIoY.png"
                />
              }
              variant="bordered"
            >
              Find out more
            </Button>
          </div>
        )}

        <Drawer
          className="bg-background/60 dark"
          isOpen={isOpen}
          placement={"bottom"}
          onOpenChange={onOpenChange}
        >
          <DrawerContent isBlurred className="text-center p-8">
            {(onClose) => (
              <>
                <DrawerHeader className="dark flex flex-col text-center gap-1 text-white/80 text-2xl wrap">
                  <span className="inline-flex text-center items-center justify-center gap-2">
                    Browse our collection of{" "}
                    <b
                      style={{
                        textShadow: "rgba(255,255,255,0.59) 0px 0px 20px",
                      }}
                      className="text-white text-3xl inline"
                    >
                      360,000+
                    </b>{" "}
                    bookable venues
                  </span>
                </DrawerHeader>
                <DrawerBody className="text-white/70 space-y-2">
                  <div className="flex flex-col justify-center items-center">
                    <svg
                      xmlns="http://www.w3.org/2000/svg"
                      width="36"
                      height="36"
                      viewBox="0 0 24 24"
                    >
                      <g fill="none" stroke="currentColor" stroke-width="1.5">
                        <path d="m9.213 10.174l2.015-1.605c1.302-1.037 1.953-1.556 2.363-1.3s.197 1.05-.228 2.636l-.04.15c-.154.572-.23.858-.096 1.086l.007.012c.14.226.438.306 1.033.465c1.072.287 1.608.43 1.702.804l.004.019c.083.376-.34.712-1.185 1.386l-2.015 1.604c-1.303 1.038-1.954 1.556-2.364 1.3s-.196-1.05.229-2.636l.04-.15c.153-.571.23-.857.095-1.086l-.007-.012c-.14-.225-.438-.305-1.033-.465c-1.072-.287-1.608-.43-1.702-.804l-.004-.019c-.083-.375.34-.712 1.186-1.385Z" />
                        <path
                          d="M2 12C2 6.477 6.477 2 12 2s10 4.477 10 10s-4.477 10-10 10S2 17.523 2 12Z"
                          opacity="0.5"
                        />
                      </g>
                    </svg>

                    <p>
                      Become instantly bookable to millions of people using
                      restaurant recommendation app or who follow social media
                      influencers.
                    </p>
                  </div>

                  <div className="flex flex-col justify-center items-center">
                    <svg
                      xmlns="http://www.w3.org/2000/svg"
                      width="36"
                      height="36"
                      viewBox="0 0 24 24"
                    >
                      <circle
                        cx="12"
                        cy="12"
                        r="10"
                        fill="currentColor"
                        opacity="0.5"
                      />
                      <path
                        fill="currentColor"
                        d="M8.575 9.447C8.388 7.363 6.781 5.421 6 4.711l-.43-.37A9.96 9.96 0 0 1 12 2c2.214 0 4.26.72 5.916 1.936c.234.711-.212 2.196-.68 2.906c-.17.257-.554.577-.976.88c-.95.683-2.15 1.02-2.76 2.278a1.42 1.42 0 0 0-.083 1.016c.06.22.1.459.1.692c.002.755-.762 1.3-1.517 1.292c-1.964-.021-3.25-1.604-3.425-3.553m4.862 8.829c.988-1.862 4.281-1.862 4.281-1.862c3.432-.036 3.896-2.12 4.206-3.173a10.006 10.006 0 0 1-8.535 8.664c-.323-.68-.705-2.21.048-3.629"
                      />
                    </svg>

                    <p>
                      From independent restaurants and bars, to chains and
                      multi-site businesses, AllTheTables helps you by making
                      your sites bookable to millions of people.
                    </p>
                  </div>

                  <div className="flex flex-col justify-center items-center">
                    <svg
                      xmlns="http://www.w3.org/2000/svg"
                      width="36"
                      height="36"
                      viewBox="0 0 24 24"
                    >
                      <path
                        fill="currentColor"
                        d="M4.979 9.685C2.993 8.891 2 8.494 2 8s.993-.89 2.979-1.685l2.808-1.123C9.773 4.397 10.767 4 12 4s2.227.397 4.213 1.192l2.808 1.123C21.007 7.109 22 7.506 22 8s-.993.89-2.979 1.685l-2.808 1.124C14.227 11.603 13.233 12 12 12s-2.227-.397-4.213-1.191z"
                      />
                      <path
                        fill="currentColor"
                        fill-rule="evenodd"
                        d="M2 8c0 .494.993.89 2.979 1.685l2.808 1.124C9.773 11.603 10.767 12 12 12s2.227-.397 4.213-1.191l2.808-1.124C21.007 8.891 22 8.494 22 8s-.993-.89-2.979-1.685l-2.808-1.123C14.227 4.397 13.233 4 12 4s-2.227.397-4.213 1.192L4.98 6.315C2.993 7.109 2 7.506 2 8"
                        clip-rule="evenodd"
                      />
                      <path
                        fill="currentColor"
                        d="m5.766 10l-.787.315C2.993 11.109 2 11.507 2 12s.993.89 2.979 1.685l2.808 1.124C9.773 15.603 10.767 16 12 16s2.227-.397 4.213-1.191l2.808-1.124C21.007 12.891 22 12.493 22 12s-.993-.89-2.979-1.685L18.234 10l-2.021.809C14.227 11.603 13.233 12 12 12s-2.227-.397-4.213-1.191z"
                        opacity="0.7"
                      />
                      <path
                        fill="currentColor"
                        d="m5.766 14l-.787.315C2.993 15.109 2 15.507 2 16s.993.89 2.979 1.685l2.808 1.124C9.773 19.603 10.767 20 12 20s2.227-.397 4.213-1.192l2.808-1.123C21.007 16.891 22 16.494 22 16c0-.493-.993-.89-2.979-1.685L18.234 14l-2.021.809C14.227 15.603 13.233 16 12 16s-2.227-.397-4.213-1.191z"
                        opacity="0.4"
                      />
                    </svg>

                    <p>
                      Bookings from our network feed into your existing
                      reservation system, making it frictionless and seamless to
                      get started.
                    </p>
                  </div>
                </DrawerBody>
                <DrawerFooter className="flex flex-row justify-center">
                  <Button
                    onPress={() =>
                      window.open("https://allthetables.com", "_blank")
                    }
                    color="secondary"
                    variant="light"
                  >
                    Learn more
                  </Button>
                </DrawerFooter>
              </>
            )}
          </DrawerContent>
        </Drawer>
      </>
    </NextUIProvider>
  );
};

export default VenueGlobe;
